<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />
    <v-form
      ref="form"
    >
      <base-text-field
        v-model="form.name"
        :counter="20"
        :rules="nameRules"
        label="Name"
        required
        @change="validate()"
      />

      <base-text-field
        v-model="form.email"
        :rules="emailRules"
        label="E-mail"
        required
        @change="validate()"
      />

      <base-text-field
        v-model="form.subject"
        :rules="subjectRules"
        label="Subject"
        required
        @change="validate()"
      />

      <base-textarea
        v-model="form.desc"
        :rules="messageRules"
        label="Your Need & Description"
        class="mb-6"
        required
        @keyup.once="validate()"
        @change="validate()"
      />
    </v-form>

    <template>
      <div
        :key="componentKey"
        class="input-group"
        style="display: flex; align-items: center; justify-content: left; text-align: center;"
      >
        <base-btn
          style="margin-right: 20px;"
          :disabled="invalid"
          :color="!theme.isDark ? 'accent' : 'white'"
          outlined
          @click="submit()"
        >
          Send message
        </base-btn>
        <vue-recaptcha
          v-if="!invalid"
          ref="recaptcha"
          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
        />
      </div>
    </template>
  </div>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha'
  import axios from 'axios'

  export default {
    name: 'BaseContactForm',
    components: {
      'vue-recaptcha': VueRecaptcha,
    },
    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],
    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },
    data: () => ({
      form: {
        id: '',
        name: '',
        email: '',
        subject: '',
        desc: '',
      },

      invalid: true,

      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 20) || 'Name must be less than 20 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      subjectRules: [
        v => !!v || 'Subject is required',
        v => (v && v.length <= 50) || 'Subject must be less than 50 characters',
      ],
      messageRules: [
        v => !!v || 'Message is required',
        v => (v && v.length <= 2000) || 'Message must be less than 2000 characters',
      ],

      select: null,
      items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      checkbox: false,
      componentKey: 0,
      captchaVerified: false,

    }),
    methods: {
      validate () {
        const validate = this.$refs.form.validate()
        if (validate === true) {
          this.invalid = false
          this.forceRerender()
        } else {
          this.invalid = true
          this.captchaVerified = false
          this.checkbox = false
        }
      },
      forceRerender () {
        this.componentKey += 1
      },
      onCaptchaVerified: function (recaptchaToken) {
        if (recaptchaToken.length > 0 && this.captchaVerified !== true) {
          this.captchaVerified = true
        } else {
          this.captchaVerified = false
        }
      },
      onCaptchaExpired: function () {
        this.$refs.recaptcha.reset()
      },
      async submit () {
        this.invalid = this.validate()
        if (!this.invalid && this.captchaVerified === true) {
          alert('message sent.')
          console.log(this.form)
          await this.submitForm()
        } else {
          alert('All input fields are required.')
          return
        }
        this.forceRerender()
        this.resetForm()
        this.resetValidation()
        this.$refs.recaptcha.reset()
      },
      async submitForm () {
        axios.post('http://localhost:3423/api/upsertMessage',
                   {
                     id: this.form.id,
                     name: this.form.name,
                     email: this.form.email,
                     subject: this.form.subject,
                     desc: this.form.desc,
                   })
          .then((res) => {
            console.log('success' + res)
          })
          .catch((error) => {
            console.log('error' + error)
          }).finally(() => {
            // Perform action in always
          })
      },

      resetForm () {
        this.invalid = true
        this.captchaVerified = false
        this.checkbox = false
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },

    },
  }
</script>
