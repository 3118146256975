<template>
  <v-theme-provider :dark="dark">
    <div>
      <!-- <base-img
        :src="require('@/assets/bleachsolutions-logo-dark.svg')"
        contain
        max-width="128"
        min-height="78"
        width="100%"
      /> -->
      <base-title
        size="body-1"
        space="4"
        title="BLEACHED SOLUTIONS"
        weight="regular"
      />

      <base-body>
        Custom software to accelerate your business needs
      </base-body>

      <base-btn
        class="mb-12"
        outlined
      >
        More Info
      </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: 'Richmond, VA',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'contact@bleachedsolutions.com',
        },
      ],
    }),
  }
</script>
