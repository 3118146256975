<template>
  <div
    :key="componentKey"
    :class="classes"
    class="base-avatar d-inline-flex"
  >
    <v-avatar
      v-if="outlined"
      :class="classes2"
      :size="outlineSize"
      :color="color"
      :style="styles"
      class="base-avatar__outline hover"
      :hover="hover"
    />

    <v-avatar
      v-if="drk"
      :color="color"
      :size="size"
      class="base-avatar__avatar"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <base-icon
        v-if="icon"
        :dark="dark"
        :size="size / 2"
      >
        {{ icon }}
      </base-icon>
    </v-avatar>
    <v-avatar
      v-if="light"
      :color="color"
      :size="size"
      class="base-light-avatar__avatar"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <base-icon
        v-if="icon"
        :dark="dark"
        :size="size / 2"
      >
        {{ icon }}
      </base-icon>
    </v-avatar>
    <v-avatar
      v-if="metal"
      :color="color"
      :size="size"
      class="base-metal-avatar__avatar"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <base-icon
        v-if="icon"
        :dark="dark"
        :size="size / 2"
      >
        {{ icon }}
      </base-icon>
    </v-avatar>
    <v-avatar
      v-if="transparent"
      :color="color"
      :size="size"
      class="base-transparent-avatar__avatar"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <base-icon
        v-if="icon"
        :dark="dark"
        :size="size / 2"
      >
        {{ icon }}
      </base-icon>
    </v-avatar>
  </div>
</template>

<script>
  import EventBus from '../../services/event-bus'
  export default {
    name: 'BaseAvatar',

    props: {
      color: String,
      dark: Boolean,
      icon: String,
      outlined: Boolean,
      hover: Boolean,
      size: {
        type: [Number, String],
        default: 56,
      },
      space2: {
        type: [Number, String],
        default: 8,
      },
    },

    data: () => ({
      drk: true,
      light: false,
      metal: false,
      transparent: false,
      componentKey: 0,
      multiply: 6,
    }),

    computed: {
      classes () {
        return [
          this.outlined && 'base-avatar--outlined',
        ]
      },
      classes2 () {
        const classes2 = [
          `mb-${this.space2}`,
        ]

        if (this.hover) {
          classes2.push('background-color: primary')
        }
        return classes2
      },
      outlineSize () {
        return Number(this.size) + (this.size / this.multiply)
      },
      styles () {
        const margin = this.size / (this.multiply * 2)
        return {
          // Aligns the outline content with the content
          margin: `-${margin}px 0 0 -${margin}px`,
        }
      },
    },
    created () {
      if (this.$vuetify.theme.dark === true && this.$vuetify.theme.metal === false) {
        this.drk = true
        this.light = false
        this.metal = false
        this.transparent = false
        this.forceRerender()
      } else if (this.$vuetify.theme.light === true) {
        this.drk = false
        this.light = true
        this.metal = false
        this.transparent = false
        this.forceRerender()
      } else if (this.$vuetify.theme.metal === true) {
        this.drk = false
        this.light = false
        this.metal = true
        this.transparent = false
        this.forceRerender()
      } else if (this.$vuetify.theme.transparent === true) {
        this.drk = false
        this.light = false
        this.metal = false
        this.transparent = true
        this.forceRerender()
      }
    },
    mounted () {
      EventBus.$on('theme', theme => {
        if (theme === 'dark') {
          this.drk = true
          this.light = false
          this.metal = false
          this.transparent = false
          this.forceRerender()
        } else if (theme === 'light') {
          this.drk = false
          this.light = true
          this.metal = false
          this.transparent = false
          this.forceRerender()
        } else if (theme === 'metal') {
          this.drk = false
          this.light = false
          this.metal = true
          this.transparent = false
          this.forceRerender()
        } else if (theme === 'transparent') {
          this.drk = false
          this.light = false
          this.metal = false
          this.transparent = true
          this.forceRerender()
        }
      })
    },
    methods: {
      forceRerender () {
        this.componentKey += 1
      },
    },
  }
</script>

<style lang="sass">
  .hover
    -webkit-transition: .8s ease-out
    -moz-transition: .8s ease-out
    -ms-transition: .8s ease-out
    -o-transition: .8s ease-out
    transition: .8s ease-out

  .hover:hover
    -webkit-transition-delay: 0s
    -moz-transition-delay: 0s
    -ms-transition-delay: 0s
    -o-transition-delay: 0s
    transition-delay: 0s

  .base-avatar
    border-radius: 50%
    position: relative
    overflow: visible

    &__outline
      position: absolute !important
      left: 0
      top: 0
    &--outlined

      .base-avatar__avatar
        border: thick solid #696966 !important
        background-color: #808080
        opacity: 88%
      .base-light-avatar__avatar
        border: thick solid #696966 !important
        background-color: white
        opacity: 88%
      .base-metal-avatar__avatar
        border: thick solid #696966 !important
        background: linear-gradient(grey,black,white)
      .base-transparent-avatar__avatar
        border: thick solid #696966 !important
        background: linear-gradient(white,transparent,cyan)
        opacity: 78%

  .base-avatar:hover
      cursor: pointer,
      theme: dark
</style>
