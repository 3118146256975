<template>
  <v-container
    style="display: block; max-width: 248px; height: 140px"
    class="d-flex justify-center"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
        style="height: 46px"
      >
        <v-switch
          v-model="darkSwitch"
          :label="' dark'"
          :color="color"
          v-bind="$attrs"
          inset
          v-on="$listeners"
          @change="activateDark(darkSwitch)"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        style="height: 46px"
      >
        <v-switch
          v-model="lightSwitch"
          :label="'light'"
          :color="color"
          v-bind="$attrs"
          inset
          v-on="$listeners"
          @change="activateLight(lightSwitch)"
        />
      </v-col>
      <!-- <v-col
        cols="12"
        md="6"
        style="height: 46px"
      >
        <v-switch
          v-model="metalSwitch"
          :label="'metal'"
          :color="color"
          v-bind="$attrs"
          inset
          v-on="$listeners"
          @change="activateMetal(metalSwitch)"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        style="height: 46px"
      >
        <v-switch
          v-model="transparentSwitch"
          :label="'glass'"
          :color="color"
          v-bind="$attrs"
          inset
          v-on="$listeners"
          @change="activateTransparent(transparentSwitch)"
        />
      </v-col> -->
      <v-row />
    </v-row>
  </v-container>
</template>

<script>

  import EventBus from '../../services/event-bus'

  export default {
    name: 'BaseDarkLightToggle',
    props: {
      color: {
        type: String,
        default: 'primary',
      },
    },
    data () {
      return {
        darkSwitch: true,
        lightSwitch: false,
        metalSwitch: false,
        transparentSwitch: false,
        isHidden: false,
      }
    },
    methods: {
      emitMethod (s) {
        EventBus.$emit('theme', s)
      },
      darkToggle (darkSwitch) {
        if (darkSwitch === true) {
          this.darkSwitch = true
          this.lightSwitch = false
          this.metalSwitch = false
          this.transparentSwitch = false
          this.$vuetify.theme.dark = true
          this.$vuetify.theme.light = false
          this.$vuetify.theme.metal = false
          this.$vuetify.theme.transparent = false
          var s = 'dark'
          this.emitMethod(s)
        }
      },
      lightToggle (lightSwitch) {
        if (lightSwitch === true) {
          this.darkSwitch = false
          this.lightSwitch = true
          this.metalSwitch = false
          this.transparentSwitch = false
          this.$vuetify.theme.dark = false
          this.$vuetify.theme.light = true
          this.$vuetify.theme.metal = false
          this.$vuetify.theme.transparent = false
          var s = 'light'
          this.emitMethod(s)
        }
      },
      metalToggle (metalSwitch) {
        if (metalSwitch === true) {
          this.darkSwitch = false
          this.lightSwitch = false
          this.metalSwitch = true
          this.transparentSwitch = false
          this.$vuetify.theme.dark = true
          this.$vuetify.theme.light = false
          this.$vuetify.theme.metal = true
          this.$vuetify.theme.transparent = false
          var s = 'metal'
          this.emitMethod(s)
        }
      },
      transparentToggle (transparentSwitch) {
        if (transparentSwitch === true) {
          this.darkSwitch = false
          this.lightSwitch = false
          this.metalSwitch = false
          this.transparentSwitch = true
          this.$vuetify.theme.dark = false
          this.$vuetify.theme.light = false
          this.$vuetify.theme.metal = false
          this.$vuetify.theme.transparent = true
          var s = 'transparent'
          this.emitMethod(s)
        }
      },
      activateDark (darkSwitch) {
        setTimeout(() => this.darkToggle(darkSwitch), 350)
      },
      activateLight (lightSwitch) {
        setTimeout(() => this.lightToggle(lightSwitch), 350)
      },
      activateMetal (metalSwitch) {
        setTimeout(() => this.metalToggle(metalSwitch), 350)
      },
      activateTransparent (transparentSwitch) {
        setTimeout(() => this.transparentToggle(transparentSwitch), 350)
      },
    },
  }
</script>
